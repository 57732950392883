import { useEffect, useRef, useState } from 'react';
import { Icon, Button } from '@squantumengine/horizon';
import FormTeamConfiguration from './components/form-team-configuration';
import FormAssignAgent from './components/form-assign-agent/form-assign-agent';
import FormOperationalSchedule from './components/form-operational-hour';
import FormDisplay from './components/form-display-configuration';
import { ConfigProvider, FormInstance, Modal } from 'antd';
import useTeamCreateEditStore, { FormTypeEnum, CreateEditSections } from './store';
import { ChannelEnum, CreateTeamFormSection } from './type';
import { useMutation, useQuery } from 'react-query';
import {
  createWebWidgetInbox,
  createWhatsappInbox,
  createKYCRejectReason,
  fetchInboxUserList,
  getInboxDetail,
  removeInboxMember,
  editWebWidgetInbox,
  editWhatsappInbox
} from '../../../services/inbox';
import { useAuthStore } from '../../../store/new/auth';
import useCCToaster from '../../../hooks/useCCToaster';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { addInboxMembers, updateWorkingHours, createTopic } from '../../../services/inbox';
import { InboxInterface } from '../../../types/new/inbox';
import { uploadVirtualBackground } from '../../../services/account';
import { WebhookIdentifierEnum } from '../../../static/communication-type';
import { initialFormWhatsappValue } from './helper';
import { WhatsAppSupportModeEnum } from '../../../shared/types/whatsapp.interface';

const TeamCreationForm = () => {
  const { inboxId } = useParams();
  const location = useLocation();

  const navigate = useNavigate();
  const { account } = useAuthStore();
  const { contextHolder, openNotification } = useCCToaster({ position: 'top', timeout: 3 });

  const { mutateAsync: handleCreateWebWidgetInbox } = useMutation(
    (data: any) => createWebWidgetInbox(account?.accountId, data),
    {
      onError: (error: any) => {
        openNotification({
          label: error?.response?.data?.message || 'Gagal membuat tim',
          variant: 'secondary',
          buttonLabel: 'Tutup'
        });
      }
    }
  );
  const { mutateAsync: handleCreateWhatsappInbox } = useMutation(
    (data: any) => createWhatsappInbox(account?.accountId, data),
    {
      onError: (error: any) => {
        openNotification({
          label: error?.response?.data?.message || 'Gagal membuat tim',
          variant: 'secondary',
          buttonLabel: 'Tutup'
        });
      }
    }
  );

  const { mutateAsync: handleEditWhatsappInbox } = useMutation(
    (data: any) => editWhatsappInbox(account?.accountId, inboxId!!, data),
    {
      onError: (error: any) => {
        openNotification({
          label: error?.response?.data?.message || 'Gagal membuat tim',
          variant: 'secondary',
          buttonLabel: 'Tutup'
        });
      }
    }
  );
  const { mutateAsync: handleEditInbox } = useMutation(
    (data: any) => editWebWidgetInbox(account?.accountId, inboxId!!, data),
    {
      onError: (error: any) => {
        openNotification({
          label: error?.response?.data?.message || 'Gagal membuat tim',
          variant: 'secondary',
          buttonLabel: 'Tutup'
        });
      }
    }
  );
  const {
    selectedSection,
    type: currentFormType,
    draft,
    formTeamConfiguration,
    channelType,
    formWhatsapp,
    setSelectedSection,
    setChannelType,
    setFormDisplay,
    setFormTeamConfiguration,
    setType,
    setAssignAgent,
    setOperationalHour,
    setInboxId,
    resetStore,
    applyDraft,
    setDraft,
    setFormWhatsapp
  } = useTeamCreateEditStore();

  const [formDisplayRef, setFormDisplayRef] = useState<FormInstance | null>(null);
  const [formTeamConfigRef, setFormTeamConfigRef] = useState<FormInstance | null>(null);
  const [assignedAgent, setAssignedAgent] = useState<string[]>([]);
  const [inboxNameTitle, setInboxNameTitle] = useState<string>('');

  const isChannelWhatsapp = channelType === ChannelEnum.WHATSAPP;
  const isSupportModeChatbot = formWhatsapp.inboxConfig.supportMode === WhatsAppSupportModeEnum.BOT;

  useQuery(['inbox', inboxId], () => getInboxDetail(account?.accountId, inboxId!!), {
    enabled: !!inboxId && currentFormType === FormTypeEnum.EDIT,
    refetchOnWindowFocus: false,
    onSuccess: (data: any) => {
      const inbox = data?.inbox as InboxInterface;
      const {
        inboxConfig,
        greetingEnabled,
        greetingMessage,
        longQueueMessage,
        waitingRoomMessage,
        oooMessage,
        workingHours,
        workingHoursEnabled
      } = inbox;
      const { mediaCallConfiguration, supportMode, decisionTreeId, ...restConfig } = inboxConfig;
      const formTeamConfiguration = {
        inboxName: inbox.name,
        ...mediaCallConfiguration,
        ...restConfig,
        websiteUrl: '',
        greetingInfo: undefined,
        target: '',
        channel: inbox.channelType,
        conversationContext: inbox.inboxConfig?.conversationContext,
        credential: inbox.inboxConfig.credential ?? WebhookIdentifierEnum.NONE,
        webhookIdentifier: inbox.inboxConfig.webhookIdentifier ?? WebhookIdentifierEnum.NONE,
        durationReminder: inbox?.inboxConfig?.durationReminder
      };
      const formDisplay = {
        greetingEnabled,
        greetingMessage,
        longQueueMessage,
        waitingRoomMessage,
        oooMessage,
        vbg: ''
      };
      setChannelType(inbox.channelType as ChannelEnum);
      setFormTeamConfiguration(formTeamConfiguration);
      setFormDisplay(formDisplay);
      setOperationalHour({
        outOfOfficeMessage: oooMessage,
        workingHoursEnabled,
        workingHours
      });
      setInboxId(inboxId ?? '');
      setInboxNameTitle(inbox.name);
      setFormWhatsapp(initialFormWhatsappValue(inbox));
    }
  });

  useQuery(['inbox', inboxId, 'members'], () => fetchInboxUserList(account.accountId, inboxId!), {
    enabled: !!inboxId && currentFormType === FormTypeEnum.EDIT,
    onSuccess: (data) => {
      const members = data?.inboxMembersList?.map((d) => d.agentId);
      setAssignAgent(members);
      setAssignedAgent(members);
    }
  });

  const mutationAssignAgent = useMutation(
    ({ inboxId, agentIds }: { inboxId: string; agentIds: string[] }) =>
      addInboxMembers({ accountId: account?.accountId, inboxId, agentIds })
  );
  const mutationUnAssignAgent = useMutation(
    ({ inboxId, agentIds }: { inboxId: string; agentIds: string[] }) =>
      removeInboxMember({ accountId: account?.accountId, inboxId, agentIds })
  );
  const mutationOperationalHour = useMutation(({ inboxId, data }: { inboxId: string; data: any }) =>
    updateWorkingHours(account?.accountId, inboxId, data)
  );
  const { mutateAsync: handleCreateTopic } = useMutation(
    ({ inboxId, topics }: { inboxId: string; topics: string[] }) =>
      Promise.all(topics.map((topic) => createTopic(inboxId, topic)))
  );
  const { mutateAsync: handleCreateRejectReason } = useMutation(
    ({ inboxId, reasons }: { inboxId: string; reasons: string[] }) =>
      Promise.all(reasons.map((reason) => createKYCRejectReason(inboxId, reason)))
  );
  const { mutateAsync: handleUploadVBG } = useMutation((file: any) =>
    uploadVirtualBackground(account?.accountId, file)
  );

  const handleCreateEditWebWidget = async () => {
    try {
      const store = useTeamCreateEditStore.getState();
      const {
        inboxName,
        target = 1,
        allowTextMessage,
        allowMediaCall = false,
        allowAttachment,
        allowRefId,
        allowConversationSummary,
        allowConversationRating,
        communicationModes,
        conversationContext,
        showVideoCallButton,
        showAudioCallButton,
        showScreenShareButton,
        showCapturePhoto,
        switchCamera,
        defaultCommunicationType,
        autoConnect,
        webhookIdentifier,
        credential,
        durationReminder
      } = store.formTeamConfiguration;
      const { greetingEnabled, greetingMessage, longQueueMessage, waitingRoomMessage, oooMessage } =
        store.formDisplay;

      const payload = {
        inboxName,
        websiteUrl: '',
        greetingInfo: { greetingEnabled, greetingMessage },
        target,
        channelType: store.channelType,
        inboxConfig: {
          allowTextMessage,
          allowMediaCall,
          allowAttachment,
          allowRefId,
          allowConversationSummary,
          allowConversationRating,
          communicationModes: Array.isArray(communicationModes)
            ? communicationModes
            : [communicationModes],
          conversationContext,
          webhookIdentifier:
            webhookIdentifier === WebhookIdentifierEnum.NONE ? undefined : webhookIdentifier,
          credential,
          mediaCallConfiguration: {
            showVideoCallButton,
            showAudioCallButton,
            showScreenShareButton,
            showCapturePhoto,
            autoConnect,
            defaultCommunicationType,
            switchCamera
          },
          durationReminder
        },
        longQueueMessage,
        waitingRoomMessage,
        oooMessage
      };

      const handler =
        currentFormType === FormTypeEnum.CREATE ? handleCreateWebWidgetInbox : handleEditInbox;
      const resInbox = await handler(payload);
      const { inboxId } = resInbox;

      if (currentFormType === FormTypeEnum.CREATE) {
        mutationAssignAgent.mutate({ inboxId, agentIds: store.assignAgent });
      } else {
        const addAgents = store.assignAgent.filter((x) => !assignedAgent.includes(x));
        const removeAgents = assignedAgent.filter((x) => !store.assignAgent.includes(x));

        if (addAgents.length > 0) {
          mutationAssignAgent.mutate({ inboxId, agentIds: addAgents });
        }
        if (removeAgents.length > 0) {
          mutationUnAssignAgent.mutate({ inboxId, agentIds: removeAgents });
        }
      }

      mutationOperationalHour.mutate({
        inboxId,
        data: {
          ...store.operationalHour,
          outOfOfficeMessage: oooMessage
        }
      });
      if (formDisplayRef?.getFieldValue('vbg')) {
        handleUploadVBG(formDisplayRef?.getFieldValue('vbg'));
      }
      if (store.topicList.length > 0 && currentFormType === FormTypeEnum.CREATE)
        handleCreateTopic({ inboxId, topics: store.topicList.map((topic) => topic.name) });
      if (store.rejectReasonList.length > 0 && currentFormType === FormTypeEnum.CREATE)
        handleCreateRejectReason({
          inboxId,
          reasons: store.rejectReasonList.map((reason) => reason.name)
        });

      Modal.destroyAll();
      openNotification({
        label:
          currentFormType === FormTypeEnum.CREATE
            ? 'Berhasil membuat tim'
            : `Perubahan tim ${inboxNameTitle} berhasil disimpan`,
        variant: 'primary',
        buttonLabel: 'Tutup'
      });
      setTimeout(() => {
        store.resetStore();
        navigate(
          currentFormType === FormTypeEnum.CREATE
            ? '/v2/dashboard/team/list'
            : `/v2/dashboard/team/detail/${inboxId}`
        );
      }, 1000);
    } catch (error) {}
  };

  const handleCreateEditWhatsapp = async () => {
    const store = useTeamCreateEditStore.getState();
    const campaignConfigurations = store.formWhatsapp.campaignConfigurations || [];
    const payload = {
      greetingEnabled: true,
      inboxName: store.formTeamConfiguration.inboxName,
      channel: ChannelEnum.WHATSAPP,
      target: 10,
      campaignConfigurations,
      inboxConfig: {
        supportMode: formWhatsapp.inboxConfig.supportMode,
        decisionTreeId: '2345'
      }
    };

    const handler =
      currentFormType === FormTypeEnum.CREATE ? handleCreateWhatsappInbox : handleEditWhatsappInbox;
    const resInbox = await handler(payload);
    const { inboxId } = resInbox;

    if (currentFormType === FormTypeEnum.CREATE) {
      mutationAssignAgent.mutate({ inboxId, agentIds: store.assignAgent });
    } else {
      const addAgents = store.assignAgent.filter((x) => !assignedAgent.includes(x));
      const removeAgents = assignedAgent.filter((x) => !store.assignAgent.includes(x));

      if (addAgents.length > 0) {
        mutationAssignAgent.mutate({ inboxId, agentIds: addAgents });
      }
      if (removeAgents.length > 0) {
        mutationUnAssignAgent.mutate({ inboxId, agentIds: removeAgents });
      }
    }

    mutationOperationalHour.mutate({
      inboxId,
      data: { ...store.operationalHour, outOfOfficeMessage: store.formDisplay.oooMessage }
    });

    Modal.destroyAll();
    openNotification({
      label:
        currentFormType === FormTypeEnum.CREATE
          ? 'Berhasil membuat tim'
          : `Perubahan tim ${inboxNameTitle} berhasil disimpan`,
      variant: 'primary',
      buttonLabel: 'Tutup'
    });
    setTimeout(() => {
      store.resetStore();
      navigate(
        currentFormType === FormTypeEnum.CREATE
          ? '/v2/dashboard/team/list'
          : `/v2/dashboard/team/detail/${inboxId}`
      );
    }, 1000);
  };

  const handleCreateEdit = async () => {
    const store = useTeamCreateEditStore.getState();
    const handler = {
      [ChannelEnum.WEB_WIDGET]: handleCreateEditWebWidget,
      [ChannelEnum.WHATSAPP]: handleCreateEditWhatsapp
    }[store.channelType as ChannelEnum];
    handler && handler();
  };

  const handleSetDraft = () => {
    const params = new URLSearchParams(location.search);
    const draftId = params.get('draftId');
    const uuid = setDraft(draftId!);
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('draftId', uuid);
    window.history.pushState({}, '', `${window.location.pathname}?${urlParams.toString()}`);
  };

  const handleFormSubmit = async () => {
    const renderModal = () => {
      const modal = Modal.confirm({
        title: `Simpan Perubahan?`,
        content: 'Apakah Anda ingin menyimpan perubahan? Pastikan data perubahan sudah benar.',
        icon: null,
        closable: true,
        footer: (
          <div className="flex flex-col space-y-2 pt-4">
            <Button variant="primary" onClick={handleCreateEdit}>
              Simpan
            </Button>
            <Button variant="secondary" onClick={() => modal.destroy()}>
              Kembali
            </Button>
          </div>
        )
      });
    };
    if (currentFormType === FormTypeEnum.EDIT) {
      if (selectedSection === CreateTeamFormSection.FORM_TEAM) {
        formTeamConfigRef
          ?.validateFields()
          .then(() => {
            renderModal();
          })
          .catch(() => {});
        return;
      } else if (selectedSection === CreateTeamFormSection.FORM_DISPLAY) {
        formDisplayRef
          ?.validateFields()
          .then(() => {
            renderModal();
          })
          .catch(() => {});
        return;
      }
      renderModal();
      return;
    }
    if (selectedSection === CreateTeamFormSection.FORM_DISPLAY) {
      formDisplayRef
        ?.validateFields()
        .then((values) => {
          setFormDisplay(values);
          renderModal();
        })
        .catch(() => {});
    } else if (selectedSection === CreateTeamFormSection.FORM_TEAM) {
      formTeamConfigRef
        ?.validateFields()
        .then((values) => {
          setFormTeamConfiguration(values);
          setChannelType(
            values.channelType === ChannelEnum.WEB_WIDGET
              ? ChannelEnum.WEB_WIDGET
              : ChannelEnum.WHATSAPP
          );
          handleSetDraft();
          if (isSupportModeChatbot && isChannelWhatsapp) {
            setSelectedSection(CreateTeamFormSection.FORM_OPERATIONAL_SCHEDULE);
            return;
          }
          setSelectedSection(CreateTeamFormSection.FORM_ASSIGN_AGENT);
          return;
        })
        .catch(() => {});
    } else if (selectedSection === CreateTeamFormSection.FORM_ASSIGN_AGENT) {
      handleSetDraft();
      setSelectedSection(CreateTeamFormSection.FORM_OPERATIONAL_SCHEDULE);
    } else if (selectedSection === CreateTeamFormSection.FORM_OPERATIONAL_SCHEDULE) {
      handleSetDraft();
      setSelectedSection(CreateTeamFormSection.FORM_DISPLAY);
    }
  };

  const handleBack = async () => {
    if (currentFormType === FormTypeEnum.CREATE) {
      if (selectedSection === CreateTeamFormSection.FORM_TEAM) {
        const modal = Modal.confirm({
          title: 'Batalkan Pembuatan Tim',
          content: 'Apakah Anda ingin membatalkan membuat tim baru?',
          icon: null,
          closable: true,
          footer: (
            <div className="flex flex-col space-y-2 pt-4">
              <Button
                data-testid="btn-confirm-cancel"
                variant="primary"
                onClick={() => {
                  resetStore();
                  navigate('/v2/dashboard/team/list');
                  modal.destroy();
                }}>
                Batalkan
              </Button>
              <Button
                variant="secondary"
                onClick={() => {
                  modal.destroy();
                }}>
                Kembali
              </Button>
            </div>
          )
        });
      }

      if (
        selectedSection === CreateTeamFormSection.FORM_OPERATIONAL_SCHEDULE &&
        formTeamConfiguration.supportMode === WhatsAppSupportModeEnum.BOT
      ) {
        setSelectedSection(CreateTeamFormSection.FORM_TEAM);
        return;
      }
    } else if (currentFormType === FormTypeEnum.EDIT) {
      const modal = Modal.confirm({
        title: 'Batalkan Perubahan',
        content: 'Apakah Anda ingin membatalkan perubahan?',
        icon: null,
        closable: true,
        footer: (
          <div className="flex flex-col space-y-2 pt-4">
            <Button
              data-testid="btn-confirm-cancel"
              variant="primary"
              onClick={() => {
                resetStore();
                navigate(`/v2/dashboard/team/detail/${inboxId}`);
                modal.destroy();
              }}>
              Batalkan
            </Button>
            <Button variant="secondary" onClick={() => modal.destroy()}>
              Kembali
            </Button>
          </div>
        )
      });
    }
  };

  const nextLabelBySection =
    selectedSection === CreateTeamFormSection.FORM_DISPLAY || currentFormType === FormTypeEnum.EDIT
      ? 'Simpan'
      : 'Selanjutnya';
  const prevLabelBySection =
    selectedSection === CreateTeamFormSection.FORM_TEAM || currentFormType === FormTypeEnum.EDIT
      ? 'Batalkan'
      : 'Sebelumnya';

  const isRendered = useRef(false);

  useEffect(() => {
    if (isRendered.current) return;
    const params = new URLSearchParams(location.search);
    const draftId = params.get('draftId');
    isRendered.current = true;
    const isCreateForm = location.pathname.includes('/create');
    setType(isCreateForm ? FormTypeEnum.CREATE : FormTypeEnum.EDIT);
    if (!!draftId) {
      draft?.[draftId] && applyDraft(draftId);
    } else if (isCreateForm) {
      resetStore();
      formTeamConfigRef?.setFieldsValue({});
    }
  }, [location.pathname]);

  return (
    <div
      className="relative bg-[#F9FBFF]"
      style={{
        height: 'calc(100vh - 4rem)'
      }}>
      {contextHolder}
      <div className="flex h-full flex-col gap-6 overflow-hidden p-8">
        <div className="flex items-center gap-5">
          <Button
            variant="text"
            className="h-8 w-8 rounded border-[1px] border-solid border-neutral-100 p-0"
            onClick={handleBack}>
            <Icon name="arrow-left" />
          </Button>
          <h2 className="m-0 text-xl">
            {currentFormType === FormTypeEnum.CREATE
              ? 'Buat Tim Baru'
              : `Edit Tim ${inboxNameTitle}`}
          </h2>
        </div>
        <div className="flex h-full max-h-full gap-6 pb-32">
          <div className="w-1/3 max-w-[400px]">
            <div className="rounded-xl bg-white p-6 shadow-md ">
              <div className="overflow-hidden rounded-xl border border-solid border-[#BAC3D1]">
                {CreateEditSections.map((section, index) => {
                  const isSelected = section.key === selectedSection;
                  const isPreviousSection =
                    currentFormType === FormTypeEnum.EDIT ? true : index <= selectedSection;
                  const hasBorderBottom = index < CreateEditSections.length - 1;
                  const handleChangeSection = (isPreviousSection: boolean, sectionKey: number) => {
                    if (currentFormType === FormTypeEnum.EDIT) {
                      if (selectedSection === CreateTeamFormSection.FORM_TEAM) {
                        formTeamConfigRef
                          ?.validateFields()
                          .then(() => setSelectedSection(sectionKey))
                          .catch(() => {});
                      } else if (selectedSection === CreateTeamFormSection.FORM_DISPLAY) {
                        formDisplayRef
                          ?.validateFields()
                          .then(() => setSelectedSection(sectionKey))
                          .catch(() => {});
                      } else {
                        setSelectedSection(sectionKey);
                      }
                    } else if (isPreviousSection) setSelectedSection(sectionKey);
                  };

                  if (
                    isChannelWhatsapp &&
                    isSupportModeChatbot &&
                    section.key === CreateTeamFormSection.FORM_ASSIGN_AGENT
                  )
                    return <></>;

                  return (
                    <div
                      key={index}
                      onClick={() => handleChangeSection(isPreviousSection, section.key)}
                      className={`${
                        isSelected || isPreviousSection ? 'cursor-pointer' : ''
                      } space-y-2 p-4 ${isSelected ? 'bg-blue-50' : ''} ${
                        hasBorderBottom
                          ? 'border-b border-l-0 border-r-0 border-t-0 border-solid border-[#BAC3D1]'
                          : ''
                      }
                    ${isPreviousSection ? '' : 'opacity-40'}
                    `}>
                      <h2 className="my-0 text-lg font-semibold">{section.name}</h2>
                      <p className="text-sm text-gray-600">{section.description}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <ConfigProvider
            prefixCls="cc"
            theme={{
              token: {
                colorPrimary: '#006ceb'
              }
            }}>
            <div className="h-full max-h-full w-2/3 overflow-y-scroll">
              {
                {
                  [CreateTeamFormSection.FORM_TEAM]: (
                    <FormTeamConfiguration getFormRef={setFormTeamConfigRef} />
                  ),
                  [CreateTeamFormSection.FORM_ASSIGN_AGENT]: <FormAssignAgent />,
                  [CreateTeamFormSection.FORM_OPERATIONAL_SCHEDULE]: <FormOperationalSchedule />,
                  [CreateTeamFormSection.FORM_DISPLAY]: (
                    <FormDisplay getFormRef={setFormDisplayRef} />
                  )
                }[selectedSection]
              }
            </div>
          </ConfigProvider>
        </div>
      </div>
      <div className="absolute bottom-0 z-50 h-16 w-full bg-white px-8 py-4">
        <div className="flex justify-end gap-2">
          <Button variant="secondary" onClick={handleBack}>
            {prevLabelBySection}
          </Button>
          <Button variant="primary" onClick={handleFormSubmit} data-testid="btn-next">
            {nextLabelBySection}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TeamCreationForm;
