import { Button } from 'antd';
import VideoIcon from '../../../assets/icons/video';
import { useTimer } from '../../../hooks/useTimer';
import ExclamationTriangleIcon from '../../../assets/icons/exclamation-triangle';
import { useCallStore } from '../../../store/call-state';
import { useEffect, useRef, useState } from 'react';
import VideoRequest from '../../../utils/webSocket/videoRequest';
import { IncomingChatCallInterface } from './incoming-call.interface';
import { DurationToString } from '../../../utils/timestamp';
import { useChatListStore } from '../../../store/chat-list';
import {
  AllowStartCall,
  CommunicationTypeEnum,
  defaultCommunicationTypeEnum
} from '../../../static/communication-type';
import { meetingRoomId as WSMeetingRoomId } from '../../../utils/webSocket';
import { useUserStatusStore } from '../../../store/user-state';
import { RoleEnum } from '../../../static/role';
import { useConversationListStore } from '../../../store/conversation-list-state';
import { MessageEventEnum } from '../../../static/message-event';
import { stopVideoNotification } from '../../../utils/notification';
import VoiceCallIcon from '../../../assets/icons/voice-call';
import { useAllowStartVideoCall } from '../../../api/hooks/kyc/useStartVideoCall';

const MAXIMUM_UNOPENED_SECOND = 10;

export default function IncomingChatCall({
  setShow,
  conversationId,
  messageId,
  startTime
}: IncomingChatCallInterface) {
  const [requiresAttention, setRequiresAttention] = useState<boolean>(false);
  const { second } = useTimer(Math.round((Date.now() - startTime) / 1000));
  const {
    isManualKYCMode,
    isVideoRequested,
    setFullScreen,
    setShowVideo,
    setMeetingRoomId,
    setIsVoiceCall,
    setShowCameraButton
  } = useCallStore();
  const { selectedCall } = useChatListStore();
  const { activeConversationList, setConversationList, setSelectingConversation } =
    useConversationListStore();
  const { userRole } = useUserStatusStore();

  useEffect(() => {
    if (second < MAXIMUM_UNOPENED_SECOND || requiresAttention) {
      return;
    }

    setRequiresAttention(false);
  }, [second, requiresAttention]);

  let firstRender = useRef(true);
  useEffect(() => {
    const isAutoVideoReady = isManualKYCMode && WSMeetingRoomId && userRole === RoleEnum.AGENT;
    if (isAutoVideoReady && firstRender.current && !isVideoRequested) {
      firstRender.current = false;
      setTimeout(() => {
        setFullScreen(true);
        handleAnswerCall();
      }, 500);
    }
    // eslint-disable-next-line
  }, [WSMeetingRoomId, isManualKYCMode]);
  const defaultCommunicationType =
    selectedCall?.inboxConfig?.mediaCallConfiguration.defaultCommunicationType;

  const isAudioOnly = defaultCommunicationType === defaultCommunicationTypeEnum.AUDIO;
  const isAudioVideo = defaultCommunicationType === defaultCommunicationTypeEnum.AUDIO_VIDEO;
  const isVoiceCall = isAudioOnly || isAudioVideo;
  // switched condition when customer request from audio call to video call
  const isSwitched =
    selectedCall?.metadata?.communicationType === defaultCommunicationTypeEnum.VIDEO;

  const { mutate } = useAllowStartVideoCall();
  const handleAnswerCall = () => {
    mutate({
      conversationId: conversationId,
      actionType: AllowStartCall.AGENT_JOINED_MEETING_ROOM
    });
    if (isManualKYCMode) setFullScreen(true);
    setIsVoiceCall(isAudioVideo || isAudioOnly);
    isVoiceCall && setShowCameraButton(isSwitched || !isVoiceCall);
    VideoRequest({ audio: true, video: !isVoiceCall || isSwitched });
    setShowVideo(true);
    setShow(false);
    localStorage.setItem('videoMessageId', messageId);

    const newCallList = [...activeConversationList];

    const newCall = newCallList.find((item) => item.conversationId === conversationId);
    if (newCall && newCall.communicationType !== CommunicationTypeEnum.VIDEO) {
      newCall.communicationType = CommunicationTypeEnum.VIDEO;
      setConversationList(MessageEventEnum.ACTIVE, newCallList);
    }

    localStorage.setItem('meetingRoomId', WSMeetingRoomId);
    setMeetingRoomId(WSMeetingRoomId);
    stopVideoNotification();
    setSelectingConversation(false);
  };

  if (localStorage?.meetingRoomId && conversationId === selectedCall?.conversationId) {
    return null;
  }
  const PrimaryButtonIcon = requiresAttention ? (
    <ExclamationTriangleIcon className="text-black-90 [&>*]:h-[18px] [&>*]:w-[22px]" />
  ) : isVoiceCall ? (
    <VoiceCallIcon />
  ) : (
    <VideoIcon className="text-base text-black-90" />
  );
  return (
    <div className="p-6">
      <div
        className={`${
          requiresAttention ? 'bg-orange-danger' : 'bg-bold-green'
        }  flex w-full flex-col rounded-lg p-6 shadow-lg`}>
        <div className="flex flex-col items-center text-base text-white">
          <p className="m-0 p-0 text-base font-semibold">
            Pelanggan ingin melakukan panggilan {isVoiceCall ? 'suara' : 'video'}
          </p>

          <div className="flex w-full justify-center">
            <p className="mt-0 text-base font-semibold">{DurationToString(second)}</p>
          </div>
        </div>
        {userRole === RoleEnum.AGENT && (
          <div className="flex flex-col gap-3">
            <Button
              onClick={handleAnswerCall}
              className="h-12 w-full rounded-full border-none bg-white text-base font-semibold">
              Jawab Panggilan {isVoiceCall ? 'Suara' : 'Video'}
              {PrimaryButtonIcon}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
