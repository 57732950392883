import React from 'react';
import { Skeleton } from 'antd';
import { normalizeIDNString } from '../../../utils/string';

interface AdditionalInfoSectionProps {
  metadata: Record<string, any>;
  loading: boolean;
  excludedKeys: string[];
}

const renderValue = (value: any): React.ReactNode => {
  if (Array.isArray(value)) {
    return value.join(', '); // Handle arrays
  } else if (typeof value === 'string' || typeof value === 'number') {
    return value; // Render directly if string or number
  } else {
    return <span>Unsupported value</span>; // Handle unsupported types gracefully
  }
};

const AdditionalInfoSection: React.FC<AdditionalInfoSectionProps> = ({
  metadata,
  loading,
  excludedKeys
}) => {
  return (
    <div>
      <h4 className="heading">Informasi Tambahan</h4>
      <div className="grid gap-2 overflow-hidden lg:grid-cols-2 lg:gap-4">
        {Object.entries(metadata)
          .filter(([key, value]) => !excludedKeys.includes(key) && value) // exclude specific keys and empty values
          .map(([key, value]) => (
            <div key={key}>
              <h5 className="heading my-0 text-xs xl:text-sm">{normalizeIDNString(key)}</h5>
              <div className="truncate">
                {loading ? (
                  <Skeleton active title={{ width: '100%', className: 'my-0' }} paragraph={false} />
                ) : typeof value === 'object' ? (
                  // Handle object types (like customerPref)
                  <div>
                    {Object.entries(value as Record<string, any>).map(
                      ([nestedKey, nestedValue]) => (
                        <div key={nestedKey}>
                          <h6 className="my-0 text-xs xl:text-sm">
                            {normalizeIDNString(nestedKey)}
                          </h6>
                          <div>{renderValue(nestedValue)}</div>
                        </div>
                      )
                    )}
                  </div>
                ) : (
                  renderValue(value) // Handle non-object values
                )}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default AdditionalInfoSection;
