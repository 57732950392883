import React from 'react';
import { normalizeIDNString } from '../../../../utils/string';
import { Paragraph, Skeleton } from '@squantumengine/horizon';

interface AdditionalInfoSectionProps {
  metadata: Record<string, any>;
  loading: boolean;
  excludedKeys: string[];
}

const renderValue = (value: any): React.ReactNode => {
  if (Array.isArray(value)) {
    return value.join(', '); // Handle arrays
  } else if (typeof value === 'string' || typeof value === 'number') {
    return value; // Render directly if string or number
  } else {
    return <span>Unsupported value</span>; // Handle unsupported types gracefully
  }
};

const AdditionalInfoSectionV2: React.FC<AdditionalInfoSectionProps> = ({
  metadata,
  loading,
  excludedKeys
}) => {
  return (
    <div>
      <h4 className="heading">Informasi Tambahan</h4>
      <div className="grid gap-2 overflow-hidden lg:grid-cols-2 lg:gap-4">
        {Object.entries(metadata)
          .filter(([key, value]) => !excludedKeys.includes(key) && value) // exclude specific keys and empty values
          .map(([key, value]) => (
            <div key={key}>
              <Paragraph weight="semibold" className="my-0 text-xs xl:text-sm">
                {normalizeIDNString(key)}
              </Paragraph>
              <div className="truncate">
                {loading ? (
                  <Skeleton  />
                ) : typeof value === 'object' ? (
                  // Handle object types (like customerPref)
                  <div>
                    {Object.entries(value as Record<string, any>).map(
                      ([nestedKey, nestedValue]) => (
                        <div key={nestedKey}>
                          <Paragraph className="my-0 text-xs xl:text-sm">{nestedKey}</Paragraph>
                          <div>{renderValue(nestedValue)}</div>
                        </div>
                      )
                    )}
                  </div>
                ) : (
                  renderValue(value) // Handle non-object values
                )}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default AdditionalInfoSectionV2;
