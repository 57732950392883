import { useCallback, useState } from 'react';
import { Empty, Menu, notification, Pagination, Select } from 'antd';
import { getUserJSON } from '../../../utils/user-json';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import Table from '../../../components/commons/Table';
import { useQuery } from 'react-query';
import { fetchAccountList } from '../../../api';
import { RoleEnum } from '../../../static/role';
import { fetchAuditTrail } from '../../../api/audit-trail';
import { debounce } from '../../../utils/rate-limit';
import { toSentenceCase } from '../../../utils/formatter';
import { Paragraph, TextField } from '@squantumengine/horizon';
import DatePickerComponent from '../dashboard/components/filter/datepicker';
import { Dayjs } from 'dayjs';
import { getIconUrl, getImageUrl } from '../../../utils/getIconUrl';
import {
  AUDIT_TRAIL_ACTION_TYPE_ENUM,
  AUDIT_TRAIL_TABLE_COLUMNS,
  DATE_FILTER_FORMAT,
  sizeChangerOptions,
  INITIAL_FILTER_VALUES
} from './static';

export const AuditTrail = () => {
  const { account, role } = getUserJSON();
  const isAdmin = role === RoleEnum.ADMIN;
  const [showActivityFilter, setShowActivityFilter] = useState(false);
  const [searchActivityFilter, setSearchActivityFilter] = useState('');
  const [selectedAccount, setSelectedAccount] = useState(account?.accountId || '');
  const [inputSearch, setInputSearch] = useState('');
  const [filters, setFilters] = useState(INITIAL_FILTER_VALUES);

  const { data: accountList = [] } = useQuery('accountList', () => fetchAccountList(), {
    enabled: isAdmin,
    select: (res) => res.data,
    onSuccess: (data) => isAdmin && setSelectedAccount(data[0]?.accountId)
  });

  const { data: auditTrailData, isLoading: isLoadingAuditTrail } = useQuery(
    ['auditTrailData', selectedAccount, filters],
    () => fetchAuditTrail(selectedAccount || '', filters),
    {
      onError: (error: any) => {
        notification.error({
          message: error?.response?.data?.message || 'Gagal memuat data',
          description: `Status: ${error?.response?.status}`
        });
      }
    }
  );

  const auditTrailList = auditTrailData?.data || [];
  const auditTrailTotalData = auditTrailData?.count || 0;

  const accountOptions = (accountList || []).map((item) => ({
    label: <span>{item.name}</span>,
    value: item.accountId
  }));

  const activityFilterOptions = [
    {
      label: <span>Semua</span>,
      value: '',
      key: ''
    },
    ...Object.values(AUDIT_TRAIL_ACTION_TYPE_ENUM).map((item) => ({
      label: <span>{toSentenceCase(item)}</span>,
      value: item,
      key: item
    }))
  ];

  const handleFilterOptions = (input: string, option?: Record<string, any>) =>
    String(option?.label?.props?.children).toLowerCase().includes(input.toLowerCase());

  const handleFilterEmail = useCallback(
    debounce((value: string) => {
      setFilters((prev) => ({ ...prev, email: value, 'page-no': 1 }));
    }, 300),
    []
  );

  const handleSearch = (value: string) => {
    setInputSearch(value);
    handleFilterEmail(value);
  };

  const toggleShowActivityFilter = () => {
    setShowActivityFilter((prev) => !prev);
    setSearchActivityFilter('');
  };

  return (
    <section className="space-y-6 p-6">
      <div className="flex items-center justify-between">
        <h2 className="heading my-0">Riwayat Audit</h2>
        <div className="flex space-x-4">
          {isAdmin && (
            <div className="flex items-center rounded-full border border-solid">
              <Select
                className="min-w-[144px] [&>.ant-select-selector>.ant-select-selection-item]:!font-semibold [&_.ant-select-arrow]:!text-black"
                options={accountOptions}
                optionFilterProp="children"
                showSearch
                variant="borderless"
                value={selectedAccount}
                popupMatchSelectWidth={false}
                suffixIcon={<DownOutlined />}
                filterOption={handleFilterOptions}
                onChange={setSelectedAccount}
              />
            </div>
          )}
        </div>
      </div>
      <div className="space-y-4 bg-white p-4 shadow-md">
        <div className="flex space-x-4">
          <div className="w-full max-w-[300px]">
            <TextField
              className="h-8"
              placeholder="Cari email"
              full
              prefix={<SearchOutlined className="text-gray-300" />}
              value={inputSearch}
              onChange={handleSearch}
            />
          </div>

          <div className="relative">
            <div
              className="flex h-8 cursor-pointer items-center space-x-2 rounded-lg border border-solid border-[#BAC3D1] px-4 py-2"
              onClick={toggleShowActivityFilter}>
              <span>Aktivitas: {toSentenceCase(filters.action) || 'Semua'}</span>
              <DownOutlined className="mt-1" />
            </div>
            {showActivityFilter && (
              <div className="absolute top-12 z-50 max-h-[250px] overflow-scroll rounded-lg bg-white shadow-lg">
                <div className="p-2">
                  <TextField
                    full
                    placeholder="Search"
                    className="h-8"
                    suffix={<SearchOutlined className="text-gray-500" />}
                    onChange={setSearchActivityFilter}
                  />
                </div>

                <Menu onClick={toggleShowActivityFilter}>
                  {activityFilterOptions
                    .filter(
                      (item) =>
                        item.value.toLowerCase().indexOf(searchActivityFilter.toLowerCase()) >= 0
                    )
                    .map((item) => (
                      <Menu.Item
                        className="relative !m-0 !px-0"
                        key={item.value}
                        onClick={() => setFilters((prev) => ({ ...prev, action: item.value }))}>
                        <div className="flex items-center">
                          <div className="h-8 w-4">
                            {item.value === filters.action && (
                              <img
                                className="full"
                                src={getIconUrl('selected-indicator.svg')}
                                alt="selected"
                              />
                            )}
                          </div>
                          {item.label}
                        </div>
                      </Menu.Item>
                    ))}
                </Menu>
              </div>
            )}
          </div>

          <div className="relative flex h-8 max-w-[256px] items-center space-x-2 rounded-lg border border-solid border-[#BAC3D1] pl-4">
            <div className="flex space-x-1">
              <span>Tanggal:</span>
              <div className="flex-1">
                <DatePickerComponent
                  onChange={function (values: [start: Dayjs, end: Dayjs]): void {
                    setFilters((prev) => ({
                      ...prev,
                      startDate: values[0]?.format(DATE_FILTER_FORMAT),
                      endDate: values[1]?.format(DATE_FILTER_FORMAT),
                      'page-no': 1
                    }));
                  }}
                  renderSelectedPreset={(value) => (
                    <span className="w-full whitespace-nowrap">{value}</span>
                  )}
                />
              </div>
            </div>
            <DownOutlined className="absolute right-2" />
          </div>
        </div>
        <Table
          columns={AUDIT_TRAIL_TABLE_COLUMNS}
          dataSource={auditTrailList}
          pagination={false}
          loading={isLoadingAuditTrail}
          locale={{
            emptyText: () => {
              return (
                <Empty
                  image={getImageUrl('empty-folder.svg')}
                  description={
                    <Paragraph weight="bold" className="text-black-rich">
                      Belum ada Riwayat Audit
                    </Paragraph>
                  }
                />
              );
            }
          }}
        />
        <div className="grid grid-cols-3">
          <div />
          <div className="flex justify-center">
            <Pagination
              current={filters['page-no']}
              total={auditTrailTotalData}
              showSizeChanger={false}
              pageSize={filters['page-size']}
              onChange={(page, pageSize) => {
                if (page !== filters['page-no'])
                  setFilters((prev) => ({ ...prev, 'page-no': page }));
              }}
            />
          </div>
          <div className="flex items-center justify-end space-x-2">
            <span className="text-gray-500">Tampilan per baris</span>
            <div className="rounded-md border border-solid border-gray-300">
              <Select
                variant="borderless"
                options={sizeChangerOptions}
                value={filters['page-size']}
                onChange={(value) =>
                  setFilters((prev) => ({ ...prev, 'page-size': value, 'page-no': 1 }))
                }
                popupMatchSelectWidth={false}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AuditTrail;
