import { CheckboxOptionType, FormInstance, RadioChangeEvent } from 'antd';
import { CommunicationTypeEnum } from '../../static/communication-type';
import { FetchedConversationInterface } from '../../utils/webSocket/websocket-interfaces';
import { NotificationInstance } from 'antd/es/notification/interface';
import { WhatsAppSupportModeEnum } from './whatsapp.interface';
export interface InboxListInterface {
  accountId: string;
  inboxList: InboxListItemInterface[];
}

export interface workingHour {
  dayOfWeek: number;
  openAllDay: boolean;
  closedAllDay: boolean;
  openHour?: number;
  openMinutes?: number;
  closeHour?: number;
  closeMinutes?: number;
}

export interface InboxConfigInterface {
  agentIdleTime?: number;
  allowAttachment: boolean;
  allowMediaCall: boolean;
  allowRefId: boolean;
  allowTextMessage: boolean;
  allowConversationSummary: boolean;
  allowConversationRating: boolean;
  webhookIdentifier?: string;
  credential?: string;
  communicationModes: Array<CommunicationTypeEnum>;
  conversationContext: string;
  mediaCallConfiguration: AllowMediaCallInterface;
  reminderIdleConversation: boolean;
  durationReminder: number;
  supportMode?: WhatsAppSupportModeEnum;
}

export interface AllowMediaCallInterface {
  showVideoCallButton: boolean;
  showAudioCallButton: boolean;
  showScreenShareButton: boolean;
  showCapturePhoto: boolean;
  autoConnect: boolean;
  defaultCommunicationType: string | null;
  switchCamera: boolean;
}

export interface InboxListItemInterface {
  accountId?: string;
  totalAgent?: number;
  inboxId: string;
  name: string;
  channel: {
    channelId: string;
    websiteToken: string;
    websiteUrl: string;
    welcomeTitle: string;
    widgetColor: string;
  };
  channelType: string;
  timezone: string;
  workingHours: workingHour[];
  workingHoursEnabled: boolean;
  enableAutoAssignment: boolean;
  greetingEnabled: boolean;
  greetingMessage: string;
  target: string;
  inboxConfig: InboxConfigInterface;
  longQueueMessage: string;
  waitingRoomMessage: string;
  oooMessage: string;
}

export interface InboxMemberListInterface {
  agentId: string;
  firstName: string;
  lastName: string;
}

export interface AgentAvailabilityInterface {
  available: boolean;
  capacity: {
    maxCallCapacity: number;
    maxChatCapacity: number;
    onGoingCallCapacity: number;
    onGoingChatCapacity: number;
  };
}

export type InboxMemberListWithCapacityInterface = InboxMemberListInterface &
  AgentAvailabilityInterface;

export interface InboxMemberInterface {
  accountId: string;
  inboxId: string;
  inboxMembersList: InboxMemberListWithCapacityInterface[];
}

export interface InboxAnalysisInterface {
  duration: string;
  target: number;
  progress: number;
}

export interface FetchAllConversationByInbox {
  size: number;
  pageNo: number;
  data: FetchedConversationInterface[];
  count: number;
}

export interface OfflineConversationInterface {
  createdAt: string;
  customer: { id: string; name: string; email: string; phone: string };
  message: string;
  messageId: string;
  status: OfflineConversationEnum;
  isDetailsSubmitted?: boolean;
}

export interface FetchOfflineConversationInterface {
  size: number;
  pageSize: number;
  offlineMessages: OfflineConversationInterface[];
  count: number;
}

export enum OfflineConversationEnum {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  BOTH = 'BOTH'
}

export interface ToggleBtnInterface extends React.InputHTMLAttributes<HTMLInputElement> {
  formInstance: FormInstance<string>;
  tooltip?: string;
  event: (checked: boolean) => void;
}

export interface RadioBtnInterface extends React.InputHTMLAttributes<HTMLInputElement> {
  event: (e: RadioChangeEvent) => void;
  options?: Array<CheckboxOptionType>;
  tooltip?: string | JSX.Element;
}

export interface DropdownInterface extends React.InputHTMLAttributes<HTMLInputElement> {
  options?: Array<CheckboxOptionType>;
  tooltip?: string;
}

export interface ValidateInboxInterface {
  form: FormInstance;
  api: NotificationInstance;
  keyChanges?: string[];
}

export interface SendNotificationInterface extends ValidateInboxInterface {
  condition: boolean;
  message: string;
  description: string;
  value: string;
}

export interface InboxListSidebarInterface {
  key: number;
  label: string;
  inboxid: string;
}

// Max BE value, if exceeded it will be 400 Bad Request
export const MAX_CALL = 2147483647;
