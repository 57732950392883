import { useEffect, useMemo, useState } from 'react';
import { Skeleton } from 'antd';
import { useCustomerDetails } from '../../../../api/hooks/customer/useCustomerDetails';
import { useChatListStore } from '../../../../store/chat-list';
import Copy from '../../copy';
import { Paragraph } from '@squantumengine/horizon';
import AdditionalInfoSectionV2 from './additional-info';

const FIELDS_INFO_DASAR = [
  ['name', 'Nama'],
  ['contactId', 'ID Pelanggan'],
  ['email', 'Email'],
  ['phone', 'No Telepon']
];

const CustomerInfo = () => {
  const [dobDelayed, setDobDelayed] = useState('');
  const { selectedCall, selectedOfflineChat } = useChatListStore();
  const { data: customerDetail, isLoading: loadingCustomerDetail } = useCustomerDetails(
    selectedCall?.contactId || '',
    !!selectedCall?.contactId
  );

  const currentCustomer = useMemo(
    () =>
      customerDetail || {
        ...selectedOfflineChat?.customer,
        contactId: selectedOfflineChat?.customer?.id
      },
    [customerDetail, selectedOfflineChat]
  );

  useEffect(() => {
    setDobDelayed('');
  }, [selectedCall, selectedOfflineChat]);

  const excludedMetadataDisplay = ['reference_id', 'communicationType', 'status'];

  return (
    <div className="max-h-[380px] overflow-y-auto">
      <div>
        {FIELDS_INFO_DASAR.map(([key, label]) => {
          // @ts-ignore: unparseable key
          const value = (currentCustomer && currentCustomer[key]) || '-';
          return (
            <div key={key} className="mb-2 grid min-h-[24px] w-full grid-cols-2 gap-4">
              <Paragraph className="text-neutral-600">
                {{
                  contactId: (
                    <div className="flex items-center">
                      <span className="truncate">{label}</span>
                      <Copy value={value} />
                    </div>
                  )
                }[key] || label}
              </Paragraph>
              <Paragraph className="truncate text-neutral-950">
                {(loadingCustomerDetail && (
                  <Skeleton active title={{ width: '100%', className: 'my-1' }} paragraph={false} />
                )) ||
                  value}
              </Paragraph>
            </div>
          );
        })}
        <div className="mb-2 grid min-h-[24px] w-full grid-cols-2 gap-4">
          <Paragraph className="text-nowrap text-neutral-600">Tanggal Lahir</Paragraph>
          <Paragraph className="flex w-full items-center truncate text-neutral-950">
            {(loadingCustomerDetail && (
              <Skeleton active title={{ width: '100%', className: 'my-0' }} paragraph={false} />
            )) ||
              dobDelayed ||
              (customerDetail && customerDetail.dateOfBirth) ||
              '-'}
          </Paragraph>
        </div>
      </div>
      <AdditionalInfoSectionV2
        metadata={selectedCall?.metadata || {}}
        loading={loadingCustomerDetail}
        excludedKeys={excludedMetadataDisplay}
      />
    </div>
  );
};

export default CustomerInfo;
