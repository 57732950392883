import { AutoComplete, Form, Input } from 'antd';
import { useAuthStore } from '../../store/new/auth';
import { useAuth } from '../../hooks/useAuth';
import { AuthNATIVEContextProps } from '../../shared/types/user.interface';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginNATIVE } from '../../api/native-login';
import { getFirstFieldErrorMessage } from '../../utils/form';
import {
  NativeLoginErrorMsgEnum,
  NativeLoginWordingEnum,
  NativePlaceholderEnum
} from '../../static/native-login';
import CardHeading from './card-heading';
import CustomLabel from './custom-input-label';
import ErrorForm from '../form/error-form/error-form';
import LinkForgotPassword from './link-forgot-password';
import { Button } from '@squantumengine/horizon';
import ErrorTtl from './error-ttl';
import { getUrlParam } from '../../utils/url-search-params';

const FormLogin = () => {
  const { prevUserList } = useAuthStore();
  const { login } = useAuth() as AuthNATIVEContextProps;
  const [loading, setLoading] = useState<boolean>(false);
  const [loginErr, setLoginErr] = useState<boolean>(false);
  const [errMsg, setErrorMsg] = useState<string>('');
  const [fastLogin] = useState<{ value: string }[]>(
    prevUserList.map((user: any) => ({ value: user.email }))
  );
  const [ttlError, setTtlError] = useState(false);
  const [ttl, setTtl] = useState(0);
  const navigate = useNavigate();
  const clientId = getUrlParam('client');

  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    setLoading(true);

    const response = await loginNATIVE(values.email, values.password, clientId!.toUpperCase());

    if ('error' in response) {
      setLoginErr(true);
      setLoading(false);
      setTtlError(false);

      if (response.message) {
        setErrorMsg(response.data.message || response.message);

        if (response.data.ttl) {
          setTtlError(true);
          setTtl(response.data.ttl);
          return;
        }

        if (
          response.status === 401 &&
          response.data.message === NativeLoginErrorMsgEnum.passwordExpired
        ) {
          navigate('/reset-password', {
            state: { userEmail: values.email, isPasswordExpired: true }
          });
        }
      }
      return;
    }

    const isFirstLogin = response.action === 'CHANGE_PASSWORD';
    const uuid = response.uuid || '';

    if (isFirstLogin) {
      navigate(`/reset?uuid=${uuid}`);
      return;
    }

    login(response);

    setLoading(false);
  };

  const onFinishFailed = () => {
    setErrorMsg(getFirstFieldErrorMessage(form));
    setLoginErr(true);
    setLoading(false);
  };

  return (
    <Form
      name="basic"
      className="w-96 rounded-lg bg-white p-6 pb-0"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      form={form}
      requiredMark={false}>
      <CardHeading>
        <CardHeading.Heading
          title={NativeLoginWordingEnum.welcome}
          description={NativeLoginWordingEnum.welcomeDescription}
        />
      </CardHeading>

      <Form.Item
        label={<CustomLabel label={NativeLoginWordingEnum.email} />}
        name={'email'}
        rules={[{ required: true, message: NativeLoginErrorMsgEnum.emailRequired }]}
        help="">
        <AutoComplete
          className="input-login"
          data-testid="email"
          size="large"
          options={fastLogin}
          placeholder={NativePlaceholderEnum.email}
        />
      </Form.Item>

      <Form.Item
        className={`mt-[24px]`}
        label={<CustomLabel label={NativeLoginWordingEnum.password} />}
        name={'password'}
        rules={[{ required: true, message: NativeLoginErrorMsgEnum.passwordRequired }]}
        help="">
        <Input.Password
          data-testid="password"
          type="password"
          size="large"
          placeholder={NativePlaceholderEnum.password}
        />
      </Form.Item>

      {loginErr && !ttlError && (
        <ErrorForm isVisible={true} message={errMsg} className="my-[24px]" />
      )}

      {ttlError && <ErrorTtl prefix={errMsg} milliseconds={ttl} />}

      <LinkForgotPassword
        form={form}
        onError={(msg) => {
          setLoginErr(true);
          setErrorMsg(msg);
        }}
      />

      <Form.Item className="mt-[24px]">
        <Button data-testid="login-button" variant="primary" type="submit" full loading={loading}>
          Masuk
        </Button>
      </Form.Item>
    </Form>
  );
};

export default FormLogin;
